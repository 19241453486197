var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:`mb-10 pb-10 ${
    _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? 'mx-1' : ''
  }`,attrs:{"flat":""}},[_c('div',{staticClass:"d-flex justify-start flex-wrap"},_vm._l((_vm.events),function(event){return _c('v-card',{key:event.no,class:`${
        _vm.$vuetify.breakpoint.mdAndUp ? 'mx-2' : ''
      } my-2 card--margins`,attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp ? '265' : '100%'}},[_c('div',[_c('v-img',{staticClass:"card--margins",attrs:{"src":_vm.url + event.base64Logo,"lazy-src":require(`@/assets/images/picture4.jpg`),"height":"175","width":_vm.$vuetify.breakpoint.mdAndUp ? '265' : '100%'},on:{"click":function($event){return _vm.$root.routeTo({
              name: 'EventCard',
              params: { no: event.no },
            })}}}),_c('span',{staticClass:"cpd--chip"},[(_vm.isWebinar(event))?_c('v-chip',{attrs:{"color":"red darken-1","dark":""}},[_vm._v(" webinar ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"mt-n2 mr-n5",staticStyle:{"width":"45%"}},[_c('CPDbadge',{attrs:{"cpd-points":event.cpdHours}})],1)])],1)],1),_c('div',{staticClass:"text-capitalize mt-2 mx-2 text-body-2 text-truncate font-weight-bold",on:{"click":function($event){return _vm.$root.routeTo({
            name: 'EventCard',
            params: { no: event.no },
          })}}},[_c('span',{staticClass:"pointer"},[_vm._v(_vm._s(event.description))])]),_c('div',{staticClass:"text-wrap mx-2 text-truncate"},[_c('span',[_vm._v(" "+_vm._s(_vm.days[new Date(event.startDate).getDay()])+", "+_vm._s(_vm.months[new Date(event.startDate).getMonth()])+" "+_vm._s(new Date(event.startDate).getDate())+", "+_vm._s(new Date(event.startDate).getFullYear())+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(event.location)+" ")],1),_c('v-btn',{staticClass:"text-capitalize mt-1 book--btn text-body-2",attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.$root.routeTo({
            name: 'booking',
            params: { no: event.no },
          })}}},[_vm._v(" Book Now "),_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-bookmark-plus")])],1)],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }