<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Login </v-card-title>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="formData.email"
              label="Email"
              append-icon="mdi-email"
              type="text"
              :rules="emailRules"
            />
            <v-text-field
              v-model="formData.password"
              label="Password"
              append-icon="mdi-lock"
              type="password"
              :rules="[(v) => !!v || 'Password is required']"
            />
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="login" @keyup.enter="login" color="primary" text>
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    formData: {
      email: "",
      password: "",
    },
    dialog: false,
  }),
  mounted() {
    Event.$on("login-form", (state) => {
      this.dialog = state;
    });
  },
  methods: {
    login: function () {
      this.$store.dispatch("Auth/login", this.formData);
      this.dialog = false;
    },
  },
};
</script>
<style scoped lang="scss"></style>
