import call from "../../service/http";
import { AuthConstants as constant, AuthService } from "./index";

export default {
  namespaced: true,
  state: {
    loading: false,
    company: {},
  },
  mutations: {
    SET_COMPANY: (state, data) => {
      state.company = data;
    },
  },
  getters: {
    user: () => AuthService.user,
    company: (state) => state.company,
  },
  actions: {
    login: (context, data) => {
      context.commit("SET_LOADING", true, { root: true });
      call("post", constant.login, data)
        .then((res) => {
          AuthService.login(res.data.data.token, res.data.data.user);
          context.commit("SET_LOADING", true, { root: false });
        })
        .catch((error) => {
          context.commit("SET_LOADING", true, { root: false });
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    logout: (context) => {
      call("get", constant.logout)
        .then(() => {
          AuthService.logout();
        })
        .catch((error) => {
          context.commit("SET_LOADING", true, { root: false });
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    company: (context) => {
      context.commit("SET_LOADING", true, { root: false });
      call("get", constant.company)
        .then((res) => {
          context.commit("SET_COMPANY", res.data.data);
          context.commit("SET_LOADING", false, { root: false });
        })
        .catch(() => {
          Event.$emit("ApiError", "Failed To fetch company info");
          context.commit("SET_LOADING", false, { root: false });
        });
    },
  },
};
